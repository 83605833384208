<template>
  <div class="els-antv-x6">
    <!-- header -->
    <div class="x--header" v-if="header.enabled">
      <slot name="header">
        <div class="default-header">
          <!-- left -->
          <div class="h--left">
            <i v-if="header.icon" :class="header.icon"></i>
            <span v-if="header.title" class="title"> {{ header.title }} </span>
          </div>
          <!-- center -->
          <div class="h--center"></div>
          <!-- right -->
          <div class="h--right"></div>
        </div>
      </slot>
    </div>
    <!-- body -->
    <div class="x--body" :class="{ 'no--header': !header.enabled }">
      <!-- node list -->
      <div id="self-theme" class="b--node-list" v-if="nodeList !== undefined">
        <slot name="list">
          <div class="default-node-list">
            <!-- search -->
            <div class="node-list--search">
              <el-input clearable size="mini" prefix-icon="el-icon-search" :placeholder="$t('ruleDesignPage.searchNode')" v-model="nodeSearchValue">
              </el-input>
            </div>
            <!-- list tree -->
            <div class="node-list--tree">
              <!-- 默认全展开 -->
              <el-collapse :value="'0,1,2,3,4,5,6,7,8,9,10'.split(',')">
                <!-- <el-collapse value="0"> -->
                <template v-for="(node, ins) in showNodeList">
                  <el-collapse-item class="node-list--group" v-if="node.type === 'group'" :key="node.label || ins"
                    :name="'' + ins">
                    <template slot="title">
                      <!-- icon -->
                      <i v-if="node.icon" :class="node.icon"></i>
                      <!-- label -->
                      <span :title="node.label">{{ node.label }}</span>
                    </template>
                    <!-- node -->
                    <template v-if="node.children && node.children.length > 0">
                      <p v-for="item in node.children" :key="item.name" @mousedown="handleAddItem(item, $event)">
                        <el-button class="node-list--item" size="mini" :title="item.label" v-bind="item">
                          {{ item.label }}
                        </el-button>
                      </p>
                    </template>
                  </el-collapse-item>
                </template>
              </el-collapse>
            </div>
            <div v-for="(node, ins) in showNodeList" :key="node.name || ins"></div>
          </div>
        </slot>
      </div>
      <!-- graph -->
      <div ref="containerBox" class="b--graph" style="display:flex">
        <div ref="graphContainer"></div>
      </div>
    </div>
    <!-- panel -->
    <els-drawer :visible="drawVisible">
      <slot name="drawer"></slot>
    </els-drawer>
  </div>
</template>

<script>
import { Graph } from "@antv/x6";
//
import { Addon } from "@antv/x6";
//
import { option, dragOptions, register, registerInstance, defaultEdgeShape } from "./config";
//
import { merge } from "lodash"
//
import { treeReset } from "./utils";
//

export default {
  name: "els-antv-x6",
  props: {
    nodeList: Array, // 节点列表 [{label,icon,disabled,name,class | style|type = ''}] 插槽 + 可懒加载
    nodes: Array, // [{name,node,...}] 自定义节点 // [node1,node2,node3,node4]
    //
    nodeElem: Object, // {name:Elem} // 额外元素面板
    //
    graphData: Object, // 图初始化数据
    //
    graphOptions: Object,
    //
    options: Object, // 组件配置对象 {header:{enabled:true,}} // autoRegister:bool
    // 事件参数配置
    eventArgs: Object,
  },
  data: () => ({
    graph: undefined, // Graph 实例
    dnd: undefined, // 拖拽插件实例
    nodeSearchValue: "",
    //
    drawVisible: false,
  }),
  computed: {
    header() {
      return { enabled: false, title: "流程图编辑", icon: "el-icon-edit" };
    },
    autoRegister() {
      const ops = this.options || { autoRegister: true };
      return ops.autoRegister === undefined || ops.autoRegister;
    },
    edgeShape() {
      const ops = this.options || {};
      //
      return ops.edgeShape || defaultEdgeShape;
    },
    showNodeList() {
      const s = this.nodeSearchValue;

      const tree = this.nodeList || [];
      //
      const filter = (n) => {
        // 配置过滤
        if (n.enabled === false) {
          return undefined;
        }
        //
        // 搜索过滤
        if(!n.label){
          n.label = n.cell ? n.cell.name : n.name;
        }
        if (s && n.type !== "group") {
          return n.label.includes(s);
        }
        //
        return true;
      };
      //
      return treeReset(tree, filter).filter(
        (n) => (n.children && n.children.length > 0) || n.type !== "group"
      );
    },
    //

  },
  methods: {
    // 初始化
    initGraph() { },
    //
    handleAddItem({ name, label }, e) {
      // console.log(name, e);
      this.apiAddNode({ shape: name, label }, e);
    },
    //
    addListener() {
      this.graph.on("cell:added", (e) => {
        const cell = e.cell;
        this.$emit("cell-added", e);
        if (cell.isEdge()) {
          this.$emit("edge-added", e);
        }
        if (cell.isNode()) {
          this.$emit("node-added", e);
        }
      });
    },
    //
    apiAddNode(cell, e) {
      if (!this.dnd || !cell) return;
      const node = this.graph.createNode(cell);
      this.dnd.start(node, e);
    },
    apiRegistCell(name, cell, overwrite = true) {
      Graph.registerNode(name, cell, overwrite);
    },
    apiOpenDraw() {
      this.drawVisible = true;
    },
    apiCloseDraw() {
      this.drawVisible = false;
    },
    //
    initOptions() {
      const ops = this.graphOptions || {};
      //
      const ds = this.edgeShape;
      //
      option.connecting.createEdge = ({ sourceCell }) => {
        let shape = ds;
        const cs = sourceCell.shape;
        const d = sourceCell.getData() || {};
        if (typeof ds === 'function') { shape = ds(sourceCell) || '' }
        if (typeof ds === 'object' && ds[cs]) { shape = ds[cs] }
        if (Object.prototype.hasOwnProperty.call(d, "edgeShape")) {
          shape = d.edgeShape;
        }
        //
        return sourceCell.model.createEdge({ shape });
      }
      const { offsetHeight: height, offsetWidth: width } = this.$refs.containerBox || {};
      const container = this.$refs.graphContainer;
      //
      return merge({ container, width, height, }, option, ops)
    },
  },
  created() {
    //
    this.autoRegister && register(Graph)
    // 外部节点注册
    if (this.nodes) {
      this.nodes.forEach(({ name, cell, overwrite }) => {
        this.apiRegistCell(name, cell, overwrite);
      });
    }
    //
    // 实例传递
    this.$emit("this", this);
  },
  mounted() {
    const options = this.initOptions();
    this.graph = new Graph(options);

    //
    this.dnd = new Addon.Dnd({ ...dragOptions, target: this.graph });
    // 注册默认 元素，工具，事件
    this.autoRegister && registerInstance(this.graph, this.eventArgs);
    //
    this.addListener();
    //
  },
};
</script>

<style lang="scss" scoped>
.els-antv-x6 {
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-height: 500px;

  .x--header {
    box-sizing: border-box;
    padding: 0 8px;
    height: 40px;
    box-shadow: 0 0 15px 0 #ddd inset;
    border-radius: 8px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;

    .default-header {
      .h--left {
        font-size: 16px;
        font-weight: bold;
        color: #666;
      }
    }
  }

  .x--body {
    height: calc(100% - 48px);

    &.no--header {
      height: 100%;
    }

    display: flex;

    .b--node-list {
      height: 100%;
      margin-right: 8px;
      padding: 8px;
      padding-right: 0;
      box-sizing: border-box;
      position: relative;
      width: 200px;
      box-shadow: 0 0 15px 0 #ddd inset;
      border-radius: 8px;
      overflow: hidden;
      // &::after{
      //   position:absolute;
      //   content:'';
      //   top:0;
      //   right:0;
      //   height:100%;
      //   width:2px;
      //   background:#ddd;
      // }

      .default-node-list {
        max-height: 100%;
        height: 100%;

        .node-list--search {
          padding-right: 8px;
          margin-bottom: 5px;
          padding-bottom: 5px;
          border-bottom: 2px solid #ddd;
        }

        .node-list--tree {
          height: calc(100% - 40px);
          box-sizing: border-box;
          padding-right: 8px;
          overflow: auto;

          ::v-deep .el-collapse {
            .el-collapse-item {
              border-radius: 8px;
              background: rgba(200, 200, 200, 0.326);
              overflow: hidden;

              &:not(:last-child) {
                margin-bottom: 5px;
              }

              .el-collapse-item__header {
                background: transparent;
                height: 32px;
                font-weight: bold;
                color: #666;
                padding-left: 8px;

                &:hover {
                  color: white;
                  background: #999;
                }

                &.is-active {
                  color: #eee;
                  background: #999;
                }
              }

              .el-collapse-item__wrap {
                background: transparent;

                .el-collapse-item__content {
                  padding: 8px;

                  >p {
                    padding: 2px 0;
                  }
                }

                .node-list--item {
                  width: 100%;
                  box-sizing: border-box;
                  display: flex;

                  &:hover {
                    color: #fff;
                  }

                  span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 100%;
                  }
                }
              }
            }
          }

        }

      }
    }

    .b--graph {
      flex: 1;
      box-shadow: 0 0 15px 0 #ddd inset;
      // border:1px solid #ddd;
      border-radius: 8px;
      overflow: hidden;
    }

    #self-theme {
      ::-webkit-scrollbar {
        position: absolute;
        width: 0px;
        height: 0px;
      }
    }
  }
}
</style>

<style>
@keyframes ant-line {
  to {
    stroke-dashoffset: -1000;
  }
}
</style>

<style lang="scss">
.status--success {
  i {
    color: #67c23a;
  }
}

.status--error {
  color: #f56c6c;
}

.status--warning {
  color: #e6a23c;
}

.els-antv-x6-selecting {
  .x6-widget-selection-inner {
    border-color: #409EFF;
  }

  .x6-widget-selection-box {
    border-color: #409EFF;
  }
}

.x6-node-selected {
  >rect {
    stroke-dasharray: 2 1;
    // stroke:#409EFF;
  }

  >.x6-port {
    >circle {
      stroke-dasharray: 2 1;
    }
  }
}
</style>
