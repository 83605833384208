// 添加自定义监听事件
//
const edgeArgs = {
  distance: "50%",
  offset: -12,
  attrs: {
    ref: "rect",
  },
};
const nodeArgs = {
  x: "90%",
  y: 0,
};
// 通用事件配置
export const events = {
  "cell:mouseenter": ({ cell }) => {
    const shape = cell.shape;
    const data = cell.getData() || {};
    if (shape.includes("static") || data.static) return;
    //
    const ops = data.options || {};
    // console.log(cell)
    // 添加删除按钮
    cell.addTools(
      {
        name: "button-remove",
        args: cell.isNode()
          ? nodeArgs
          : cell.labels.length > 0
          ? edgeArgs
          : { distance: "50%" },
      },
      ops
    );

    // 判断是否需要显示编辑按钮
    if (cell.isNode() && cell.shape.includes("edit")) {
      cell.addTools({ name: "button-edit" }, ops);
    }
    //
  },
  "cell:mouseleave": ({ cell }) => {
    const rns = ["button-remove", "button-edit"];
    const data = cell.getData() || {};
    const ops = data.options || {};
    rns.forEach((name) => {
      if (cell.hasTool(name)) {
        cell.removeTool(name, ops);
      }
    });
  },
  // 节点可编辑 label
  "node:dblclick": ({ cell, e }) => {
    const shape = cell.shape;
    const data = cell.getData() || {};
    if (shape.includes("static") || data.static) return;
    // 设置状态 为静默模式，这样其他更新就会被静止触发事件！
    cell.setData({ options: { silent: true } });
    //
    cell.addTools({
      name: "node-editor",
      args: {
        event: e,
        setText({ cell, value }) {
          cell.notify("labelEdit", {
            newValue: value,
            oldValue: cell.label,
            cell,
          });
          cell.setProp({ label: value });
          // cell.setAttrs({label:{text:value}});
          cell.setData({ options: { silent: false } });
        },
      },
    });
  },
  // 编辑
  "node:contextmenu": (e) => {
    e.cell.notify("edit", e);
    // const shape = cell.shape;
    // const data = cell.getData() || {};
    // if (shape.includes("static") || data.static) return;
    // // 设置状态 为静默模式，这样其他更新就会被静止触发事件！
    // cell.setData({options:{silent:true}})
    // //
    // cell.addTools({
    //   name: "node-editor",
    //   args: {
    //     event: e,
    //     setText({cell,value}){
    //       cell.notify('labelEdit',{newValue:value,oldValue:cell.label,cell})
    //       cell.setProp({label:value})
    //       // cell.setAttrs({label:{text:value}});
    //       cell.setData({options:{silent:false}})
    //     }
    //   },
    // });
  },
};

export const keyBordEvents = {
  // 复制元素
  "ctrl+c"() {
    const cells = this.getSelectedCells();
    cells.forEach((cell) => cell.setData({ copyCellId: cell.id }));
    if (cells.length) {
      this.copy(cells, { deep: true, useLocalStorage: true });
      this.trigger("cells:copy", cells);
    }
    return false;
  },
  // 粘贴元素
  "ctrl+v"(e, attrs = {}) {
    // if (!this.isClipboardEmpty()) {
    const nodeProps = attrs.nodeProps ?? {};
    const edgeProps = attrs.edgeProps ?? {};
    const cells = this.paste({
      offset: 32,
      useLocalStorage: true,
      nodeProps,
      edgeProps,
    });
    this.cleanSelection();
    this.select(cells);
    this.trigger("cells:paste", cells);
    // }
    return false;
  },
  //
  delete() {
    const cells = this.getSelectedCells();
    //
    if (cells.length > 0) {
      this.removeCells(cells);
    }
  },
  // 剪切
};

// 自动注册事件 API
export const register = (graph, eventArgs = {}) => {
  if (!graph) return;
  Object.keys(events).forEach((name) => {
    const fun = events[name];
    graph.on(name, fun);
  });

  Object.keys(keyBordEvents).forEach((name) => {
    const fun = keyBordEvents[name];
    const arg = eventArgs[name];
    graph.bindKey(name, (e) => {
      fun.apply(graph, [e, arg]);
    });
  });
};
