import { register as registerCells } from "./cells";
import { register as registerTools } from "./tools";
import { register as registerEvents } from "./events";


export const defaultEdgeShape = 'edge-ant'
// 边链接通用配置
export const connecting = {
  snap: true, // boolean | {radio:10px} 是否自动吸附 radio 可配置判定半径 ，默认 50px
  allowBlank: false, // 是否允许连接到画布空白位置的点，默认为 true
  allowMulti: true, // boolean | 'withPort' | ((this: Graph, args: ValidateConnectionArgs) => boolean) | 自定义 sourcePort targetPort ,
  allowLoop: false, //
  allowNode: false, // 是否允许边链接到节点（非节点上的链接桩），默认为 true。
  allowEdge: false, // 是否允许边链接到另一个边，默认为 true。
  allowPort: true, // 是否允许边链接到链接桩，默认为 true。
  highlight: true, // 拖动边时，是否高亮显示所有可用的连接桩或节点，默认值为 false。
  //
  validateConnection({ edge, sourceCell, sourcePort, targetCell, targetPort }) {
    // console.log(
    //   "=== validate ===",
    //   this,
    //   edge,
    //   sourceCell,
    //   sourcePort,
    //   targetCell,
    //   targetPort
    // );
    // 出边校验
    if (sourceCell && sourceCell.isNode()) {
      const sp = sourceCell.getPort(sourcePort);
      const spg = sp ? sp.group : undefined;
      if (spg && !spg.includes("out")) return false;
      // 单出边校验
      if (spg && spg === "outOne") {
        const ses = sourceCell.model
          .getEdges()
          .filter((e) => e.getSourceCell() == sourceCell && e.getSourcePortId() === sourcePort && e != edge);
        if (ses.length > 0) {
          ses.forEach((e) => sourceCell.model.removeCell(e));
        }
      }
    }
    // 入边校验
    if (targetCell && targetCell.isNode()) {
      const tp = targetCell.getPort(targetPort);
      const tpg = tp ? tp.group : undefined;
      if (tpg && !tpg.includes("in")) return false;
      // 已链接校验
      const edges = targetCell.model.getEdges().filter(e => e.getTargetCell() == targetCell)
      //
      if(edges.filter(e => e.getSourceCell() == sourceCell).length > 0) {return false;}
      // 单入边校验
      if (tpg && tpg === "inOne") {
        const tes = edges.filter((e) => e.getTargetPortId() === targetPort && e != edge);
        //
        if (tes.length > 0) {
          return false;
        }
      }
    }
    //
    return true;
  }, //在移动边的时候判断连接是否有效，如果返回 false，当鼠标放开的时候，不会连接到当前元素，否则会连接到当前元素。
};

// 节点和边的交互
export const interacting = function() {
  // this => graph
};

// 图形容器通用配置
export const option = {
  background: "#eee",
  autoResize: true,
  panning: {
    enabled: true,
    // modifiers: "shift",
  },
  grid: {
    size: 10,
    visible: true,
    type: "doubleMesh",
    args: [
      {
        color: "#e2e2e2", // 主网格线颜色
        thickness: 1, // 主网格线宽度
      },
      {
        color: "#d3d3d3", // 次网格线颜色
        thickness: 2, // 次网格线宽度
        factor: 5, // 主次网格线间隔
      },
    ],
  },
  
  mousewheel: true,
  //
  highlighting: {
    magnetAvailable: {
      name: "stroke",
      args: {
        attrs: {
          fill: "#67C23A",
          stroke: "#67C23A",
        },
      },
    },
    magnetAdsorbed: {
      name: "stroke",
      args: {
        attrs: {
          fill: "#fff",
          stroke: "#47C769",
        },
      },
    },
  },
  // 元素选择、框选 配置
  selecting: {
    enabled: true,
    modifiers: "ctrl",
    className:'els-antv-x6-selecting',
    showNodeSelectionBox: false,
    rubberband:true, //  是否启用框选
    filter(n){return !n.shape.includes('static')},
  },
  // 剪切板配置 是否启用剪切板
  clipboard: {
    enabled: true,
    // useLocalStorage: true,
  },
  // 是否启用键盘事件
  keyboard: {
    enabled: true,
  },
  // 加载边的连线通用配置
  connecting,
};

// 拖拽组件 dnd 配置
export const dragOptions = {
  scaled: true,
  animation: true,
};

// 通用边配置
// export const edgeOptions = {
//   // 普通边，可展示文字
//   "edge-svg": {
//     inherit: "edge",
//     defaultLabel: {
//       markup: [
//         {
//           tagName: "rect",
//         },
//         {
//           tagName: "text",
//         },
//       ],
//       attrs: {
//         text: {
//           fill: "#000",
//           fontSize: 12,
//           textAnchor: "middle",
//           textVerticalAnchor: "middle",
//           pointerEvents: "none",
//         },
//         rect: {
//           ref: "text",
//           fill: "#c5ece5",
//           stroke: "#7ab9ca",
//           strokeWidth: 2,
//           rx: 4,
//           refWidth: "110%",
//           refHeight: "160%",
//           xAlign: "middle",
//           yAlign: "middle",
//         },
//       },
//       position: {
//         distance: 0.5,
//         options: {
//           keepGradient: true,
//           ensureLegibility: true,
//         },
//       },
//     },
//   },
// };

// // 通用节点配置
// export const nodeOptions = {};

// 默认的自定义节点 所有节点都只有 矩形节点
// 可编辑的节点
// 不可编辑的节点
// 不可移动的节点

// 默认的自定义边
// 可编辑的边
// 不可编辑的边
// 不可删除的边

// 自动注册 cells,tools,events
export const register = (Graph) => {
  registerCells(Graph);
  registerTools(Graph);
};

export const registerInstance = (graph,eventArgs) => {
  registerEvents(graph,eventArgs);
}
