//  通用，零散 工具方法

// 判断是否是 url
export const isURL = (s) => /^http[s]?:\/\/.*/.test(s);

// localstorage 保存数据
export const saveLocal = (key, val) => {
  const t = typeof val;
  if (t === 'object') {
    localStorage.setItem(key, JSON.stringify(val))
  } else {
    localStorage.setItem(key, val)
  }
}

// 获取 localstorage 保存的数据 type 可以用来对数据做初步类型转换 目前仅支持 json对象转换
export const getLocal = (key, type) => {
  const r = localStorage.getItem(key) || undefined;
  if (type == 'object' && r) {
    return JSON.parse(r)
  }
  return r;
}

// 清空 localstorage
export const clearLocal = () => {
  localStorage.clear()
}

const getType = (o) => {
  const t = Object.prototype.toString.call(o);
  return /^\[object (.*)\]$/.exec(t)[1].toLowerCase();
}
// 对象深度克隆
export const clone = (obj = {}) => {
  const f = (o) => {
    const t = getType(o)
    const r = t == 'array' ? [] : {};
    if (t == 'array') {
      o.forEach(v => {
        const t = getType(v)
        if (t == 'object' || t == 'array') {
          r.push(f(v))
        } else {
          r.push(v)
        }
      })
    }
    else if (t == 'object') {
      Object.keys(o).forEach(k => {
        const v = o[k]
        const t = getType(v)
        if (t == 'object' || t == 'array') {
          r[k] = f(v);
        } else {
          r[k] = v
        }
      })
    }
    //
    return r;
  }

  return f(obj);
}

// 对象深度合并
export const combine = (m = {}, n = {}) => {
  //
  const res = clone(m);
  const has = Object.prototype.hasOwnProperty;

  const f = (r, s) => {
    const is = typeof r == typeof s && typeof r == 'object'
    if (!is) return s;
    const t = Array.isArray(r) ? 'array' : 'object'
    //
    if (t == 'array') {
      return [...r, ...s]
    } else {
      Object.keys(s).forEach(k => {
        const b = has.call(r, k);
        const v = s[k]
        if (!b) {
          r[k] = v;
        } else {
          r[k] = f(r[k], v)
        }
      })
      return r;
    }
  }
  //
  return f(res, n);
}
// 防抖
export const debounce = (fn, t) => {
  let delay = t || 500;
  let timer;
  return function () {
    let args = arguments;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, args);
    }, delay);
  };
};

// 获取对象属性
export const getObjValue = (key='',obj = {}) => {
  if(!key) return undefined;
  const ks = key.split('.');
  if(ks.length == 0) return undefined;
  let res = obj;
  ks.forEach(k => {
    if(res && typeof res == 'object'){
      res = res[k];
    }else{
      res = undefined;
    }
  })
  return res;
}

// 从 v-bind:key 或者 :key 中获取值
export const getBindValue = (key,val,obj) => {
  const reg = /^\s?(v-bind)?:(\w+)\s?$/;
  let res = []
  if(reg.test(key)){
    const k = reg.exec(key)[2]
    const v = getObjValue(val,obj);
    res = [k,v]
  }else{
    res = [key,val];
  }
  return res;
}


// 遍历
export const treeMap = (tree = [], fn = node => node, cfg = {}) => {
  const isSource = cfg.isSource || false;
  const s = isSource ? tree : clone(tree);
  const ck = cfg.children || 'children';
  // 节点遍历方法
  const f = (list, pn, ins) => {
    [...list].forEach((n, i) => {
      // 判断节点是否存在！
      if (n) {
        fn(n, list, i, pn, ins); // 调用
        const c = n[ck]
        if (c && c.length > 0) {
          f(c, n, i)
        }
      }
    })
  }
  f(s, undefined, undefined)
  return s;
}

// 重置树节点属性,包括 children 的名称都可以改，返回一颗新的树,保持该树的结构不变 并且当返回值为 undefined 时，删除该节点。。。
export const treeReset = (tree = [], fn, cfg = {}) => {
  // menus 真实 children Name 
  const csk = cfg.sourceChildren || cfg.children || 'children';
  const ctk = cfg.children || 'children'
  const f = (n, l, i, pn, ins) => {
    const o = fn(n, l, i, pn, ins)
    if (!o) {
      l.splice(l.indexOf(n), 1)
      if(l.length === 0){
        delete pn[ctk]
      }
      return;
    }
    else if(o === true){
      return;
    }
    const c = n[csk]
    const ks = Object.keys(n)
    ks.forEach(k => delete n[k]) // 清除原对象所有数据
    const oks = Object.keys(o)
    oks.forEach(k => n[k] = o[k]) // 重新设置原对象属性
    if (!o[ctk] && c && c.length > 0) {
      n[ctk] = c; // 重新设定原对象 children 属性
    }
  }
  return treeMap(tree, f, cfg);
}